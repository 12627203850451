.spot-action-bar
  display: grid
  grid-template-columns: 1fr
  padding: $spot-spacing-0-75
  background-color: $spot-color-basic-gray-6

  &--left,
  &--right
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: center
    justify-content: flex-end

    > .spot-action-bar--action
      margin-left: $spot-spacing-0_5

  &--left
    > .spot-action-bar--action
      margin-bottom: $spot-spacing-0_5

  @media #{$spot-mq-action-bar-change}
    grid-template-columns: 1fr auto

    &--left,
    &--right
      > .spot-action-bar--action
        margin-left: 0
        margin-bottom: 0

    &--left
      justify-content: flex-start

      > .spot-action-bar--action:not(:last-child)
        margin-right: $spot-spacing-1

    &--right
      justify-content: flex-end

      > .spot-action-bar--action:not(:first-child)
        margin-left: $spot-spacing-1

//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2022 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.op-files-tab
  .op-files-tab--file-upload
    margin-top: $spot-spacing-0-75

  .op-tab-content--tab-section_no-header
    // Remove any top margin of the top most elements, if the header is not displayed.
    .op-files-tab--file-upload
      margin-top: 0

    .op-files-tab--file-list .op-files-tab--file-list-item:first-child
      margin-top: 0

  .op-files-tab--file-list
    margin-bottom: $spot-spacing-0-75

    .op-files-tab--file-list-item
      .op-files-tab--file-list-item-action
        padding-left: 0
        padding-right: 0
        text-decoration: none

        &.view-not-allowed
          opacity: 0.5

        &.disabled
          opacity: 0.5
          pointer-events: none

        &:hover
          .op-files-tab--file-list-item-title
            text-decoration: underline

        .op-files-tab--file-list-item-title
          @include spot-body-small

          line-height: $spot-spacing-1-5
          word-break: normal
          @include text-shortener
          padding-right: $spot_spacing-0-5

        .op-files-tab--file-list-item-text
          @include spot-caption()

          color: $spot-color-basic-gray-3
          flex-shrink: 0

          &:not(:last-child)
            margin-right: $spot-spacing-0-5

      .op-files-tab--file-list-item-avatar
        display: inline-block

      .op-files-tab--file-list-item-floating-actions
        padding-right: 0

      .op-files-tab--file-list-item-floating-wrapper
        &_disabled:hover
          background: none

      .op-files-tab--file-list-item-floating-text
        @include spot-body-small()

        padding-right: $spot-spacing-0-25
        overflow: hidden
        color: var(--primary-color)

        &-icon
          margin-right: $spot-spacing-0-5

  &--storage-info-box
    margin-top: 0.875rem
    display: grid
    align-items: center
    grid-template: "icon text" "button button" / auto 1fr

    &-icon
      color: #9A9A9A
      grid-area: icon
      width: 3.375rem
      height: 3.375rem
      font-size: 1.75rem
      margin-right: $spot_spacing-0-5

    .text-box
      grid-area: text

      .text-box-header
        font-weight: 700
        line-height: $spot-spacing-1-5

      .text-box-content
        @include spot-body-small()

        color: #9A9A9A

    .button-box
      grid-area: button
      display: flex
      justify-content: end

    &-button
      margin: $spot_spacing-0-75 0 0

      &:not(:last-child)
        margin-right: $spot-spacing-0-5

  &--icon
    width: $spot-spacing-1-5

    &_pdf
      color: #B93A33

    &_img
      color: #0081C7

    &_mov
      color: #7C006E

    &_txt
      color: #9A9A9A

    &_doc
      color: #006E8F

    &_sheet
      color: #007528

    &_presentation
      color: #EF9E56

    &_form
      color: #87E2C1

    &_dir
      color: #00BDF3

    &_default
      color: #9A9A9A

    &_clip
      color: #333333

    &_nextcloud
      color: #1A67A3

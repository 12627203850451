.spot-drop-modal
  position: relative
  display: inline-flex

  &--body
    $border-radius: 5px

    @include spot-z-index("drop-modal", 1)
    pointer-events: all
    opacity: 1

    position: fixed
    top: $spot-spacing-1
    height: auto
    bottom: $spot-spacing-3_5
    left: $spot-spacing-1
    right: $spot-spacing-1
    width: calc(100vw - (2 * #{$spot-spacing-1}))
    max-height: calc(100vh - (#{$spot-spacing-3_5} + #{$spot-spacing-1}))

    box-shadow: $spot-shadow-light-mid
    background: $spot-color-basic-white
    border-radius: $border-radius

    display: flex
    flex-direction: column

    @media #{$spot-mq-landscape}
      bottom: $spot-spacing-1
      right: $spot-spacing-3_5
      max-height: calc(100vh - (2 * #{$spot-spacing-1}))
      width: calc(100vw - (#{$spot-spacing-3_5} + #{$spot-spacing-1}))

    @media #{$spot-mq-drop-modal-in-context}
      position: absolute
      top: unset
      bottom: unset
      left: unset
      right: unset
      width: auto
      height: auto
      max-width: calc(100vw - (2 * #{$spot-spacing-1}))
      max-height: calc(100vh - (2 * #{$spot-spacing-1}))

      &_left-top
        right: 100%
        top: 0%

      &_left-center
        right: 100%
        top: 50%
        transform: translateY(-50%)

      &_left-bottom
        right: 100%
        bottom: 0%

      &_bottom-left
        top: 100%
        left: 0%

      &_bottom-center
        top: 100%
        left: 50%
        transform: translateX(-50%)

      &_bottom-right
        top: 100%
        right: 0%

      &_right-top
        left: 100%
        top: 0%

      &_right-center
        left: 100%
        top: 50%
        transform: translateY(-50%)

      &_right-bottom
        left: 100%
        bottom: 0%

      &_top-left
        bottom: 100%
        left: 0%

      &_top-center
        bottom: 100%
        left: 50%
        transform: translateX(-50%)

      &_top-right
        bottom: 100%
        right: 0%

    // TODO: At some point, this needs to be a cleverer selector.
    // Though most action bars will probably be at the bottom of a drop-modal,
    // this does not necessarily have to be the case. In that case, this'll look weird.
    > .spot-action-bar,
    > .spot-container > .spot-action-bar
      border-bottom-left-radius: $border-radius
      border-bottom-right-radius: $border-radius

  &_opened::before
    @include spot-z-index("drop-modal", 0)
    display: block
    content: ''
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba($spot-color-basic-black, 0.5)

    @media #{$spot-mq-drop-modal-in-context}
      display: none

  &--close-button
    display: block
    position: fixed
    top: auto
    height: auto
    bottom: $spot-spacing-0_75
    width: calc(100% - 2 * $spot-spacing-1)
    left: 50%
    transform: translateX(-50%)
    background-color: $spot-color-basic-white

    @media #{$spot-mq-landscape}
      left: unset
      right: $spot-spacing-0_75
      transform: none

    @media #{$spot-mq-drop-modal-in-context}
      display: none

.spot-header-big
  @include spot-header-big
  padding: 0
  margin: 0

.spot-header-small
  @include spot-header-small
  padding: 0
  margin: 0

.spot-subheader-big
  @include spot-subheader-big
  padding: 0
  margin: 0

.spot-subheader-small
  @include spot-subheader-small
  padding: 0
  margin: 0

.spot-body-big
  @include spot-body-big
  padding: 0
  margin: 0

.spot-body-small
  @include spot-body-small
  padding: 0
  margin: 0

.spot-caption
  @include spot-caption
  padding: 0
  margin: 0

.spot-header-big,
.spot-header-small,
.spot-subheader-big,
.spot-subheader-small,
.spot-body-big,
.spot-body-small,
.spot-caption
  &_bold
    font-weight: bold

  &_italic
    font-style: italic

  &_regular
    font-weight: normal
    font-style: normal
